import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w06RS05DnD3 = () => (
  <Layout>
    <SEO title="w06RS05DnD3" />

    <h1 id="ueberschrift">Fantasy-Rollenspiel</h1>
    <h2>basierend auf DnD (dritte Edition) &emsp;  ©&nbsp;2019</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w06RS05DnD3&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Ob als Mensch, Elf, Zwerg, Oger oder ein anderes Wesen: erleben Sie 
        bei einem gemütlichen Beisammensein eine fantastische Welt, indem Sie sie
        als Kämpfer, Gaukler, Magiekundiger, etc. "bereisen" und ihre Rätsel lösen.
        Lassen Sie ihrer Fantasie im Rahmen des Zusammenspiels und unter Anleitung eines
        "Spielleiters" (game master GM) freien Lauf 
        und genießen Sie bei lustigen und interessanten Anekdoten die gemeinsame Zeit.
      </p>
      <p>
        Dungeons &amp; Dragons (D&amp;D oder DnD) ist ein amerikanisches Pen-&amp;-Paper-Rollenspiel, dessen dritte
        Edition diesem Kurs als Grundlage dient.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz/Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: + ggf. verschiedenseitige Würfel +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze/Tische
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w06RS05DnD3
